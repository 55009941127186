<template>
  <div>
    <vx-card actionable>
      <vs-row vs-w="12" style="margin-bottom: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="8"
          vs-w="4"
        >
          <vs-button
            
            color="primary"
            type="gradient"
            icon="add"
            @click="addBudgetDataPopup()"
          >Add Budget</vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-w="12" v-if="activatedUsers.length != 0">
          <div style="width: 100%">
            <vs-table max-items="10" search :data="activatedUsers">
              <template slot="header">
                <h3 style="font-weight: 600; color: #7367f0">
                  Marketing Budgets
                </h3>
              </template>
              <template slot="thead">
                <vs-th sort-key="source">Source</vs-th>
                <vs-th sort-key="course">Course</vs-th>
                <vs-th sort-key="budget">Budget</vs-th>
                <vs-th sort-key="spent">Spent</vs-th>
                <vs-th sort-key="date">Date</vs-th>
                <vs-th sort-key="update">Update</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].source">{{
                    data[indextr].source
                  }}</vs-td>
                  <vs-td :data="data[indextr].course">{{
                    data[indextr].course
                  }}</vs-td>
                  <vs-td :data="data[indextr].budget">{{
                    data[indextr].budget
                  }}</vs-td>
                  <vs-td :data="data[indextr].spent">
                    <small>{{ data[indextr].spent }}</small>
                  </vs-td>
                  <vs-td>{{ formatDate(tr.date) }}</vs-td>
                  <vs-td>
                    <vs-button
                      size="medium"
                      color="primary"
                      type="flat"
                      @click="EditBudget(tr)"
                      icon="edit"
                    ></vs-button
                  ></vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-col>
        <div v-else class="not-found">
          <b>No Data Found</b>
        </div>
      </vs-row>
      <vs-popup
        :title="`${popup_type} Marketing Budget Data`"
        class="popupp"
        :active.sync="add_budget_data_popup"
      >
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-offset="1"
            vs-w="2"
            class="mb-4"
            >Source:</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="9"
            class="mb-4"
          >
            <vs-select
              placeholder="Select"
              style="width: 280px"
              v-model="selectSource"
             :disabled="popup_type == 'Update'"
            >
              <vs-select-item
                :key="indextr"
                :value="source.label"
                :text="source.label"
                v-for="(source, indextr) in sources"
              />
            </vs-select>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-offset="1"
            vs-w="2"
            class="mb-4"
            >Course:</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="9"
            class="mb-4"
          >
            <vs-select
              placeholder="Select"
              style="width: 280px"
              v-model="selectCourse"
              :disabled="popup_type == 'Update'"
            >
              <vs-select-item
                :key="indextr"
                :value="course.label"
                :text="course.label"
                v-for="(course, indextr) in courses"
              />
            </vs-select>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-offset="1"
            vs-w="2"
            class="mb-4"
            >Budget:</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="9"
            class="mb-4"
          >
            <vs-input
              class="inputx"
              style="width: 280px"
              v-model="budget"
              type="number"
            /><br />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-offset="1"
            vs-w="2"
            class="mb-4"
            >Spent:</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="9"
            class="mb-4"
          >
            <vs-input
              class="inputx"
              style="width: 280px"
              v-model="spent"
              type="number"
            /><br />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-offset="1"
            vs-w="2"
            class="my-3"
            >Date:</vs-col
          >
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="9"
            class="my-3"
          >
            <datepicker
              :disabled="popup_type == 'Update'"
              placeholder="Select Date"
              :format="formatDate"
              v-model="dates"
              style="position: absolute; width: 15%"
            ></datepicker>
          </vs-col>
        </vs-row>
        <vs-row class="gap">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              type="gradient"
              size="small"
              style="font-size: 14px"
              color="success"
              @click="addBudgetData()"
              >{{ popup_type }}</vs-button
            >&nbsp;&nbsp;
            <vs-button
              type="gradient"
              size="small"
              style="font-size: 14px"
              color="danger"
              @click="clearBudget()"
              >Cancel</vs-button
            >
          </vs-col>
        </vs-row>
      </vs-popup>
      <vs-row style="margin-top: 1%" v-if="last_page > 1">
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="12"
        >
          <vs-pagination
            :total="last_page"
            v-model="current_page"
          ></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import constants from "../../constants.json";
export default {
  components: {
    "v-select": vSelect,
    Datepicker,
  },
  data() {
    return {
      no_access_to_edit:true,
      current_page: 1,
      last_page: 1,
      add_budget_data_popup: false,
      activatedUsers: [],
      sources: [
        {
          id: 1,
          label: "Google",
        },
        {
          id: 2,
          label: "LinkedIn",
        },
        {
          id: 4,
          label: "Meta",
        },
        {
          id: 5,
          label: "YouTube",
        },
      ],
      courses: [
        {
          id: 1,
          label: "CMA",
        },
        {
          id: 2,
          label: "CPA",
        },
        {
          id: 3,
          label: "USP",
        },
      ],
      selectSource: "Meta",
      selectCourse: "CMA",
      dates: "",
      spent: 0,
      budget: 0,
      popup_type: "Add",
      marketing_budget_id: 0,
    };
  },
  watch: {
    current_page(val) {
      this.getBudgetData(val);
    },
    add_budget_data_popup(bool) {
      if (!bool) {
        this.clearBudget();
      }
    },
  },
  mounted() {
    this.clearBudget();
    let id = localStorage.getItem('user_id')
    if (id == 937 || id == 3053 || id == 1300 || id == 132 || id == 39) {
      this.no_access_to_edit = true
    }else{
      this.no_access_to_edit = false
    }
  },
  methods: {
    getBudgetData() {
      let url = `${constants.SERVER_API}getMarketingBudgetdata?page=${this.current_page}`;

      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.activatedUsers = response.data.data;
          this.last_page = response.data.last_page;
        })
        .catch((error) => {
          if (error.response.data.message != undefined) {
            this.showNotification(error.response.data.message, "danger", 2000);
          } else {
            this.showNotification(error, "danger", 2000);
          }
        });
    },
    addBudgetData() {
      if (this.dates != "") {
        if (this.budget == "") {
          this.budget = 0;
        }
        if (this.spent == "") {
          this.spent = 0;
        }
        let obj = {
          id: this.marketing_budget_id,
          date: this.customFormatDate(this.dates),
          budget: Number(this.budget),
          spent: Number(this.spent),
          source: this.selectSource,
          course: this.selectCourse,
        };
        let url = `${constants.SERVER_API}addMarketingBudgetData`;
        if (this.popup_type == "Update") {
          url = `${constants.SERVER_API}updateMarketingBudgetData?id=${this.marketing_budget_id}`;
        }
        axios.post(url, obj, {headers: {Authorization: `Bearer ${localStorage.userAccessToken}`}})
          .then((response) => {
            console.log(response);
            this.showNotification(`Record ${this.popup_type}d successfully`, "success");
            this.clearBudget();
          })
          .catch((error) => {
            if (error.response.data.message != undefined) {
              this.showNotification(error.response.data.message,"danger");
            } else {
              this.showNotification(error, "danger", 2000);
            }
            this.clearBudget();
          });
      } else {
        this.showNotification("All fields are mandatary", "danger");
      }
    },
    EditBudget(tr) {
      if (this.no_access_to_edit) {
        this.popup_type = "Update";
        this.selectSource = tr.source;
        this.selectCourse = tr.course;
        this.dates = this.formatDate(tr.date);
        this.spent = tr.spent;
        this.budget = tr.budget;
        this.marketing_budget_id = tr.id;
        this.add_budget_data_popup = true;
        this.disableScroll();
      }else{ this.showNotification("Unauthorized user, budget cannot be updated", "danger") }

    },
    addBudgetDataPopup() {
      this.add_budget_data_popup = true;
      this.disableScroll();
    },
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    customFormatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    clearBudget() {
      this.add_budget_data_popup = false;
      this.popup_type = "Add";
      this.enableScroll();
      this.marketing_budget_id = 0;
      this.spent = 0;
      this.budget = 0;
      this.dates = "";
      this.getBudgetData(this.current_page);
    },
    disableScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;

      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    },
    enableScroll() {
      window.onscroll = function () {};
    },
    showNotification(text, color) {
      this.$vs.notify({
        text: text,
        color: color,
      });
    },
  },
};
</script>
<style >
.input-search{
  margin-left:5%
}
.popupp > .vs-popup {
  width: 25% !important;
  height: auto;
}
.vdp-datepicker__calendar {
  bottom: -180px;
}
.not-found {
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 30px 0px;
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2px;
  color: #bebcbc;
}
@media only screen and (max-width: 1400px) {
  .not-found {
    font-size: 11px;
  }
}
</style>
  